export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58')
];

export const server_loads = [0,3];

export const dictionary = {
		"/(app)": [~4,[2]],
		"/admin": [43,[3]],
		"/admin/commerce": [~44,[3]],
		"/admin/commerce/discounts/[discount_id]": [~45,[3]],
		"/admin/commerce/orders/[order_id]": [~46,[3]],
		"/admin/commerce/products/[product_id]": [~47,[3]],
		"/admin/content": [~48,[3]],
		"/admin/content/assets/[asset_id]": [~49,[3]],
		"/admin/content/edit/[page_id]": [~50,[3]],
		"/admin/content/form": [~51,[3]],
		"/admin/content/form/[form_id]": [~52,[3]],
		"/admin/events": [~53,[3]],
		"/admin/events/[event_id]": [~54,[3]],
		"/admin/users": [~55,[3]],
		"/admin/users/memberships/[membership_id]": [~57,[3]],
		"/admin/users/new": [58,[3]],
		"/admin/users/[user_id]": [~56,[3]],
		"/(app)/blog": [7,[2]],
		"/(app)/blog/manage": [9,[2]],
		"/(app)/blog/manage/[slug]": [10,[2]],
		"/(app)/blog/[slug]": [~8,[2]],
		"/(app)/events": [~11,[2]],
		"/(app)/events/pid/[product_id]": [~14,[2]],
		"/(app)/events/registration": [~15,[2]],
		"/(app)/events/[event_id]": [~12,[2]],
		"/(app)/events/[event_id]/edit": [~13,[2]],
		"/(app)/forgotpass": [16,[2]],
		"/(app)/forgotpass/confirm": [17,[2]],
		"/(app)/forgotpass/reset/[token]": [~18,[2]],
		"/(app)/interactive_diagrams": [19,[2]],
		"/(app)/interactive_diagrams/inpatient-rooms": [20,[2]],
		"/(app)/join": [~21,[2]],
		"/(app)/login": [~22,[2]],
		"/(app)/logout": [~23,[2]],
		"/(app)/notifications": [~24,[2]],
		"/(app)/profile": [~25,[2]],
		"/(app)/research/knowledge_repository": [~26,[2]],
		"/(app)/research/knowledge_repository/edit/[entry_id]": [~27,[2]],
		"/(app)/research/knowledge_repository/keypoint_summary/[entry_id]": [~28,[2]],
		"/(app)/research/knowledge_repository/saved": [~29,[2]],
		"/(app)/signup": [~30,[2]],
		"/(app)/sitemap": [~31,[2]],
		"/(app)/sra": [32,[2]],
		"/(app)/sra/about/intent": [33,[2]],
		"/(app)/sra/assessment/new": [~35,[2]],
		"/(app)/sra/assessment/[sra_id]": [~34,[2]],
		"/(app)/sra/dashboard": [~36,[2]],
		"/(app)/store": [~37,[2]],
		"/(app)/store/cart": [~38,[2]],
		"/(app)/store/cart/checkout": [~39,[2]],
		"/(app)/store/orders": [~40,[2]],
		"/(app)/store/orders/[order_id]": [~41,[2]],
		"/(app)/store/products/[product_id]": [~42,[2]],
		"/(app)/[...url_slug]/edit": [~6,[2]],
		"/(app)/[...url_slug]": [~5,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';